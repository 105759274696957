import { createContext, useReducer, useContext, useEffect } from 'react'
import Cookies from 'js-cookie'
import { ANALYTICS } from 'constants/analytics'
import { Analytics } from 'components/Analytics'
import { createAnalytics, plugins } from '@achieve/cx-event-horizon/browser'
import { isEqual } from 'lodash-es'
import useSearchParamChecker from 'utils/queryManipulation/useSearchParamChecker'

const AnalyticsContext = createContext()

const analyticsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_AUTH0_USER':
      return {
        ...state,
        auth0_user_id: action.auth0User.user_id,
        auth0_email: action.auth0User.email,
        profile_id: action.auth0User.profile_id,
      }
    case 'SET_PAGE_VARIATION':
      return {
        ...state,
        pageVariation: action.payload.pageVariation,
      }
    case 'SET_SESSION_ID':
      return {
        ...state,
        session_id: action.id,
      }
    case 'SET_TRACKING_DATA':
      return {
        ...state,
        trackingData: action.payload.trackingData,
      }
    case 'SET_PAGE_STATE_LOADING':
      return {
        ...state,
        isPageLoaded: false,
        trackPageComplete: false,
      }
    case 'SET_PAGE_STATE_LOADED':
      return {
        ...state,
        trackingData: action.payload.trackingData,
        isPageLoaded: true,
      }
    case 'SET_TRACK_PAGE_COMPLETE':
      return {
        ...state,
        trackPageComplete: true,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const useConfig = () => {
  const {
    state: { config },
  } = useContext(AnalyticsContext)
  return { config }
}

const usePageVariation = () => {
  const {
    state: { pageVariation },
  } = useContext(AnalyticsContext)
  return { pageVariation }
}

const initAnalyticsInstance = (config = {}) => {
  const analyticsInstance = createAnalytics({
    application: config.NEXT_PUBLIC_ANALYTICS_APP_NAME,
    debug: parseInt(config.NEXT_PUBLIC_ANALYTICS_DEBUG) === 1 ? true : false,
    plugins: [
      plugins.analyticsFreedom({
        application: config.NEXT_PUBLIC_ANALYTICS_APP_NAME,
        isDebug: parseInt(config.NEXT_PUBLIC_ANALYTICS_DEBUG) === 1 ? true : false,
      }),
      plugins.analyticsTealium({
        application: config.NEXT_PUBLIC_ANALYTICS_APP_NAME,
      }),
    ],
  })
  return { analyticsInstance }
}

const AnalyticsProvider = ({
  children,
  config,
  pageVariation,
  pageSectionsVariations,
  loadEventProps = {},
}) => {
  const isDisabledParamPresent = useSearchParamChecker('disableFeatures', 'analytics')

  // This is a dummy context object that will be used as the default value for the analytics provider when disabling analytics
  const dummyContext = {
    state: {},
    dispatch: () => {},
  }

  // useEffect to dispatch an action to update the pageVariation state whenever the pageVariation prop changes.
  useEffect(() => {
    if (!isEqual(state.pageVariation, pageVariation)) {
      dispatch({
        type: 'SET_PAGE_VARIATION',
        payload: {
          pageVariation,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageVariation])

  const { analyticsInstance } = initAnalyticsInstance(config)
  // The current state. During the first render, it’s set to init(initialArg) or initialArg (if there’s no init).
  // The 2nd argument in the useReducer is just the initial state meaning if we want to update anything in there when data changes
  // we have to manually update the reducer state with a useEffect and dispatch.
  // https://react.dev/reference/react/useReducer

  const [state, dispatch] = useReducer(analyticsReducer, {
    browser_id: Cookies.get(ANALYTICS.BROWSER_ID_KEY_NAME),
    session_id: Cookies.get(ANALYTICS.SESSION_ID_KEY_NAME),
    auth0_user_id: undefined,
    auth0_email: undefined,
    profile_id: undefined,
    isPageLoaded: false,
    eventQueue: [],
    trackingData: {},
    config,
    analyticsInstance,
    pageVariation,
    trackPageComplete: false,
  })

  const providerValue = isDisabledParamPresent ? dummyContext : { state, dispatch }
  const childrenContent = isDisabledParamPresent ? (
    children
  ) : (
    <Analytics
      pageVariation={pageVariation}
      pageSectionsVariations={pageSectionsVariations}
      loadEventProps={loadEventProps}
    >
      {children}
    </Analytics>
  )

  return (
    <AnalyticsContext.Provider value={providerValue}>{childrenContent}</AnalyticsContext.Provider>
  )
}

export { AnalyticsProvider, AnalyticsContext, useConfig, usePageVariation }
